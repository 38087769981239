import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { withPage } from "../PageContainer";

class LandingPage extends React.Component {
  render() {
    return (
      <Wrapper>
        <img className="banner" src={"./images/003.png"} width="100%" />
        <Padding />
        <div style={{ display: "flex" }}>
          <div className="btns-wrapper">
            <BackBtn to="/">
              <img
                className="back-btn"
                src={"./images/007.png"}
                width="115px"
              />
              <p>回前頁</p>
            </BackBtn>
            <BackBtn to="/">
              <img
                className="back-btn"
                src={"./images/008.png"}
                width="115px"
              />
              <p>回首頁</p>
            </BackBtn>
          </div>
          <div className="btns-wrapper">
            <Btn to="/intro">
              <p>空軍介紹</p>
            </Btn>
            <Btn to="/unit-intro">
              <p>單位介紹</p>
            </Btn>
          </div>
        </div>
        <BottomDeco />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background-color: white;
  padding: 30px;
  display: flex;
  flex: 1;
  flex-direction: column;

  & > .banner {
    background-color: lightgray;
    border-radius: 30px 30px 0px 0px;
  }

  & .btns-wrapper {
    flex-grow: 1;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    & > .row {
      display: flex;
      flex-direction: row;
    }
  }
`;

const Padding = styled.div`
  flex: 1;
  /* align-self: stretch; */
`;

const BackBtn = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  margin: 10px;

  & > p {
    color: #3e3a39;
    text-align: center;
    font-size: 30px;
    letter-spacing: 4pt;
    margin-left: 16px;
  }
`;

const Btn = styled(Link)`
  text-decoration: none;
  flex: 1;
  padding: 25px 0px;
  margin: 10px;
  border: 4px solid #3e3a39;
  border-radius: 20px;

  & > p {
    font-family: "GenSekiGothic-B";
    color: #3e3a39;
    text-align: center;
    font-size: 45px;
    letter-spacing: 6pt;
  }
  :active {
    background-color: #06347a;
    & > p {
      color: #fff;
    }
  }
`;

const BottomDeco = styled.div`
  margin-top: 60px;
  height: 50px;
  border-radius: 0px 0px 30px 30px;
  background-color: #f0f0f0;
`;

export default withPage(LandingPage);
